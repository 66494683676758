import { Controller } from "@hotwired/stimulus";
import sendSentryError from "../common/sentry_error/sentry_error";
export default class extends Controller {
  static targets = [
    "takePhotoButton",
    "startButton",
    "stopButton",
    "video",
    "testVideo",
    "recording",
    "canvas",
    "photoReviewFront",
    "photoReviewBack",
    "errorMessage",
  ];

  connect() {
    if (this.hasVideoTarget && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      this.getCameraStream("environment", this.videoTarget);
    }

    if (this.hasTestVideoTarget && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      this.getCameraStream("user", this.testVideoTarget);
    }

    if (this.hasRecordingTarget && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      this.showFirstStep();
      this.getCameraStream("user", this.recordingTargets).then((mediaRecorder) => {
        this.mediaRecorder = mediaRecorder;
        this.handleMediaRecorder();
      });
    }

    if (this.hasPhotoReviewFrontTarget && localStorage.getItem("stat_dec_photo_id_front")) {
      this.photoReviewFrontTarget.src = localStorage.getItem("stat_dec_photo_id_front");
      if (localStorage.getItem("stat_dec_photo_id_back")) {
        this.photoReviewBackTarget.src = localStorage.getItem("stat_dec_photo_id_back");
      }
    }
  }

  getCameraStream(facingMode, targets) {
    let constraints = {
      video: {},
      audio: false,
    };

    if (this.isMobileDevice() && facingMode) {
      constraints.video.facingMode = facingMode;
    }

    return navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        this.cameraStream = stream;

        if (!(targets instanceof Array)) {
          targets = [targets];
        }

        targets.forEach((target) => {
          target.srcObject = stream;
          target.play();
        });

        return new MediaRecorder(stream);
      })
      .catch((error) => {
        let errorMessage;

        switch (error.name) {
          case "NotAllowedError":
            errorMessage = "Camera access was denied. Please grant permissions and try again.";
            break;
          case "NotFoundError":
            errorMessage =
              "No camera device was found. Please ensure your camera is connected and working.";
            break;
          case "NotSupportedError":
            errorMessage =
              "Your browser doesn't support camera access. Please try with a different browser.";
            break;
          case "AbortError":
            errorMessage = "Camera access was aborted. Please try again.";
            break;
          default:
            errorMessage = `An error occurred while accessing the camera. (${error.name}) Please try again later.`;
        }

        this.errorMessageTarget.textContent = errorMessage;

        // Double, but we want to leave the old way for now.
        sendSentryError("Stat dec: " + error);
        throw this.getError(error);
      });
  }

  isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  getError(error) {
    let newError = new Error(error);
    newError.name = `StatDec: ${error.name}`;

    return newError;
  }

  takePhoto() {
    const context = this.canvasTarget.getContext("2d");

    const videoWidth = this.videoTarget.videoWidth;
    const videoHeight = this.videoTarget.videoHeight;
    const videoAspectRatio = videoWidth / videoHeight;

    this.canvasTarget.width = 800;
    this.canvasTarget.height = 800 / videoAspectRatio;

    const idSide = window.location.pathname.split("/")[4];

    context.drawImage(this.videoTarget, 0, 0, this.canvasTarget.width, this.canvasTarget.height);
    const dataURL = this.canvasTarget.toDataURL("image/jpeg", 0.9);
    localStorage.setItem(`stat_dec_photo_id_${idSide}`, dataURL);
  }

  sendPhoto() {
    const dataURLFront = localStorage.getItem("stat_dec_photo_id_front");
    const dataURLBack = localStorage.getItem("stat_dec_photo_id_back");
    const formData = new FormData();
    formData.append("photo_id[photo_id_front]", this.dataURItoBlob(dataURLFront));
    formData.append("photo_id[photo_id_back]", this.dataURItoBlob(dataURLBack));
    this.uploadData("upload_photo_id", formData)
      .then(() => {
        localStorage.removeItem("stat_dec_photo_id_front");
        localStorage.removeItem("stat_dec_photo_id_back");
        window.location.href =
          "/next_of_kin/statutory_declaration/practice/" + this.data.get("funeralidValue");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  uploadData(endpoint, data) {
    return fetch(
      "/next_of_kin/statutory_declaration/" + this.data.get("funeralidValue") + `/${endpoint}`,
      {
        method: "PATCH",
        body: data,
        headers: {
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  handleMediaRecorder() {
    this.chunks = [];
    this.mediaRecorder.start();

    this.mediaRecorder.ondataavailable = (event) => {
      this.chunks.push(event.data);
    };

    this.mediaRecorder.onstop = () => {
      if (this.cameraStream) {
        this.cameraStream.getTracks().forEach((track) => track.stop());
      }

      const blob = new Blob(this.chunks, { type: "video/mp4" });
      const formData = new FormData();
      formData.append("video_and_signature_recordings[declaration_video]", blob);

      if (this.flowFinished) {
        this.getSignaturePad()
          .getSignatureData()
          .then(this.blobToBase64)
          .then((base64String) => {
            formData.append("video_and_signature_recordings[signature_blob]", base64String);

            this.uploadData("upload_video_and_signature", formData)
              .then(() => {
                window.location.href =
                  "/next_of_kin/statutory_declaration/success/" + this.data.get("funeralidValue");
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          });
      }
    };
  }

  handleStopMediaRecorder() {
    this.flowFinished = true;
    if (this.mediaRecorder) {
      this.mediaRecorder.stop();
    }
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0) byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.readAsDataURL(blob);
    });
  }

  showFirstStep() {
    document.getElementById("stat-dec-first-step-section").style.display = "";
    document.getElementById("stat-dec-second-step-section").style.display = "none";
    document.getElementById("stat-dec-third-step-section").style.display = "none";
    document.getElementById("stat-dec-second-step-thumbnail").style.display = "none";
  }

  showSecondStep() {
    document.getElementById("stat-dec-first-step-section").style.display = "none";
    document.getElementById("stat-dec-second-step-section").style.display = "";
    document.getElementById("stat-dec-third-step-section").style.display = "none";
    document.getElementById("stat-dec-second-step-thumbnail").style.display = "";
    this.getSignaturePad().show();
  }

  showThirdStep(event) {
    if (this.validateSignature(event)) {
      document.getElementById("stat-dec-first-step-section").style.display = "none";
      document.getElementById("stat-dec-second-step-section").style.display = "none";
      document.getElementById("stat-dec-third-step-section").style.display = "";
      document.getElementById("stat-dec-second-step-thumbnail").style.display = "none";
      this.handleStopMediaRecorder();
    }
  }

  toggleButton() {
    const checkbox = document.getElementById("tos-checkbox");
    const button = document.querySelector(".button--primary");

    if (checkbox.checked) {
      button.classList.remove("disabled");
    } else {
      button.classList.add("disabled");
    }
  }

  getSignaturePad() {
    const signaturePadElement = document.querySelector("[data-controller='signature_pad']");
    if (signaturePadElement) {
      const signaturePadController = Stimulus.getControllerForElementAndIdentifier(
        signaturePadElement,
        "signature_pad"
      );

      if (signaturePadController) {
        return signaturePadController;
      }
    }
  }

  validateSignature(event) {
    const signaturePad = this.getSignaturePad();
    if (signaturePad) {
      if (signaturePad.sigPad.isEmpty()) {
        event.preventDefault();
        document.querySelector(".signature-error").style.display = "block";
        return false;
      } else {
        document.querySelector(".signature-error").style.display = "none";
        return true;
      }
    }
  }
}
